import React, { useState, useEffect } from 'react';
import $ from "jquery";
import { Link } from 'react-scroll'

export default function Navbar(props) {
  const [selected, setSelected] = useState('GB');
  useEffect(() => {
    loadScript()
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });

    $(document).ready(function () {
      $(".navbar_right li a").click(function () {
        $('button.navbar-toggler').trigger('click');
      });
    });
  }

  return (
    <>
      <nav className="navbar navbar-expand-xl bg-light main_navbar">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={require("../assets/images/logo.png")} className='img-fluid brand_logo' alt='logo' />
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className='collapse navbar-collapse navbar_right' id="navbarSupportedContent">
            <ul class="nav ms-auto">
              <li class="nav-item">
                <Link to="home" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Home</Link>
              </li>
              {/* <li class="nav-item">
                <Link to="about" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">About Us</Link>
              </li>
              <li class="nav-item">
                <Link to="why" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Why Us?</Link>
              </li>
              <li class="nav-item">
                <Link to="roadmap" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Roadmap</Link>
              </li>
              <li class="nav-item">
                <Link to="token" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Token</Link>
              </li>
              <li class="nav-item">
                <Link to="faq" spy={true} smooth={true} duration={250} color="transparent" className="nav-link">Faq</Link>
              </li> */}
              <li class="nav-item">
                <a href="https://www.kada.market/auth-pages/login" className="nav-link primary_btn">Login</a>
              </li>
              <li class="nav-item">
                <a href="https://www.kada.market/auth-pages/login?referral=VWW1-KRNR-WDJ1" className="nav-link primary_register_btn">Register</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}