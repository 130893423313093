import React from 'react';

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>
          <div className='footer_panel_top'>
            <div>
              <h3 className='main_title_small'>Get Referral</h3>
              <h2 className='main_title'>Newsletter</h2>
            </div>
            <div className='form_inline'>
              <input className='form-control' type="email" placeholder="Email Address" />
              <button className='primary_btn'>Submit</button>
            </div>
          </div>
          <div className='footer_panel_bottom'>
            <p>© Copyright {new Date().getFullYear()} by KaneDama. All rights Reserved.</p>
            <ul className='footer_social_links'>
              <li><a href="/" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="/" target="_blank"><i class="fab fa-reddit-alien"></i></a></li>
              <li><a href="/" target="_blank"><i class="fab fa-medium-m"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );  
}