import React, { useEffect } from 'react';
import Footer from '../components/Footer.js';
import Navbar from '../components/Navbar.js';
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var roadmap_settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
      }
    }
  ]
};

export default function Home(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className='page_header' name="home">
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-12 col-lg-6 col-xl-5'>
              <ScrollAnimation animateIn='slideInUp'><h1>We assist Projects and Communities in establishing and constructing the future of Web3.</h1></ScrollAnimation>
              <ScrollAnimation animateIn='slideInUp'><h5>The most straightforward solution for distributing your project.</h5></ScrollAnimation>
              {/* <ScrollAnimation animateIn='slideInUp'><button className='primary_btn mt-4'>Buy Token</button></ScrollAnimation> */}
            </div>
            <div className='col-md-12 col-lg-6 col-xl-7'>
              <ScrollAnimation animateIn='slideInUp'>
                <div className='banner_img'>
                  <img src={require("../assets/images/banner_img.png")} className='img-fluid' alt='Banner' />
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className='main_wrapper'>

        {/* About Section */}
        <section name="about">
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-6'>
                <ScrollAnimation animateIn='slideInUp'><img src={require("../assets/images/img_01.png")} className='img-fluid' alt='Banner' /></ScrollAnimation>
              </div>
              <div className='col-md-12 col-lg-6'>
                <ScrollAnimation animateIn='slideInUp'><h3 className='main_title_small'>About Us</h3></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><h2 className='main_title'>The World’s 1st Platform that offers Rewards</h2></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><p className='inner_para'>Introducing the platform that rewards its users and simplifies the process of buying and selling tokens for investors. Our blockchain based marketplace connects buyers and sellers to facilitate transactions involving digital goods and assets using cryptocurrency. By combining traditional marketplace elements with smart contracts and cutting-edge blockchain technology, we are able to analyze user preferences and create a seamless platform for connecting people and conducting transactions</p></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><button className='primary_btn mt-4'><a href="https://www.kada.market/auth-pages/login">Purchase Token</a></button></ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* Why Us Section */}
        <section className='whyus_section grey_bg' name="why">
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <ScrollAnimation animateIn='slideInUp'><h3 className='main_title_small'>Why Us?</h3></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><h2 className='main_title'>Why Choose Our KaneDama Token</h2></ScrollAnimation>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-lg-6 col-xl-3'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box features_box'>
                    <img src={require("../assets/images/icon_01.png")} className='img-fluid' alt='Icon' />
                    <h4>Low transaction fee's</h4>
                    <p className='inner_para'>Our transaction fees are consistently low.</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-3'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box features_box'>
                    <img src={require("../assets/images/icon_02.png")} className='img-fluid' alt='Icon' />
                    <h4>Protect the identity</h4>
                    <p className='inner_para'>To ensure the safety of your account, we use strong verification processes and two-factor authentication (2FA).</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-3'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box features_box'>
                    <img src={require("../assets/images/icon_03.png")} className='img-fluid' alt='Icon' />
                    <h4>Security</h4>
                    <p className='inner_para'>To protect our user's wallets, we always utilize the latest technology.</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-3'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='primary_box features_box'>
                    <img src={require("../assets/images/icon_04.png")} className='img-fluid' alt='Icon' />
                    <h4>Mobile</h4>
                    <p className='inner_para'>All of our products are fully compatible with mobile devices.</p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        {/* <section>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <ScrollAnimation animateIn='flipInX'>
                  <div className='cta_section_01'>
                    <div>
                      <h4>Membership  Plan</h4>
                      <h2>Coming Soon</h2>
                    </div>
                    <img src={require("../assets/images/img_02.png")} className='img-fluid' alt='Icon' />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section> */}

        {/* Roadmap Section */}
        {/* <section name="roadmap">
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <ScrollAnimation animateIn='slideInUp'><h3 className='main_title_small'>Process</h3></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><h2 className='main_title'>Company Roadmap</h2></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><p className='inner_para'>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In id massa quis odio rutrum aliquet non ac lectus.</p></ScrollAnimation>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-md-12 col-lg-12'>
                <ScrollAnimation animateIn='slideInUp'>
                  <Slider {...roadmap_settings} className="roadmap_slider">
                    <div className='roadmap_slider_div'>
                      <h3>Listing to the major exchanges</h3>
                      <p>Sed efficitur ex sit amet massa scelerisque scelerisque. </p>
                    </div>
                    <div className='roadmap_slider_div'>
                      <h3>Platform integration to marketplaces</h3>
                      <p>Aliquam erat volutpat. Aenean interdum finibus efficitur. </p>
                    </div>
                    <div className='roadmap_slider_div'>
                      <h3>Crypto Wallet version release</h3>
                      <p>Praesent dapibus dolor felis, eu ultrices elit molestie.</p>
                    </div>
                    <div className='roadmap_slider_div'>
                      <h3>Lorem Ipsum dummy text of typesetting industry</h3>
                      <p>There are many variations majority have suffered alteration.</p>
                    </div>
                    <div className='roadmap_slider_div'>
                      <h3>Contrary Lorem Ipsum is not simply random text</h3>
                      <p>Readable content of a page when looking at its layout. </p>
                    </div>
                    <div className='roadmap_slider_div'>
                      <h3>Lorem Ipsum is not simply random text</h3>
                      <p>It is a long established of a page when looking at its layout. </p>
                    </div>
                  </Slider>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section> */}

        {/* Token Sale Section */}
        {/* <section className='grey_bg token_section' name="token">
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <ScrollAnimation animateIn='slideInUp'><h3 className='main_title_small'>Token Sale</h3></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><h2 className='main_title'>Our Token Distribution</h2></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><p className='inner_para'> Proin gravida fringilla dui sit amet tincidunt. Curabitur rutrum efficitur leo, vel tincidunt leo consequat vitae. Praesent et nulla at mi eleifend accumsan quis in nulla.</p></ScrollAnimation>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-md-12 col-lg-6'>
                <ScrollAnimation animateIn='slideInUp'><img src={require("../assets/images/token_chart.png")} className='img-fluid' alt='FAQ' /></ScrollAnimation>
              </div>
              <div className='col-md-12 col-lg-6 mb-0'>
                <ScrollAnimation animateIn='slideInUp'>
                  <ul className='chart_list'>
                    <li><span>Community Dev</span><span>10%</span></li>
                    <li><span>Staking Rewards</span><span>17%</span></li>
                    <li><span>Advisors</span><span>8%</span></li>
                    <li><span>Seed</span><span>5%</span></li>
                    <li><span>Team Allocation</span><span>15%</span></li>
                    <li><span>Public</span><span>4%</span></li>
                    <li><span>Strategic</span><span>15%</span></li>
                    <li><span>Liquidity Pool</span><span>6%</span></li>
                    <li><span>Private Sale	</span><span>20%</span></li>
                  </ul>
                  <div className='text-center'>
                    <button className='primary_btn mt-4'>Buy Token</button>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section> */}

        {/* Team Section */}
        {/* <section>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <ScrollAnimation animateIn='slideInUp'><h3 className='main_title_small'>Executive Team</h3></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><h2 className='main_title'>Team Members</h2></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><p className='inner_para'>Lorem Ipsum is simply dummy text ever sincehar the 1500s, when an unknownshil printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p></ScrollAnimation>
              </div>
            </div>
            <div className='row mt-5'>
              <div class="col-sm-6 col-md-4 col-lg-3">
                <ScrollAnimation animateIn='slideInUp'>
                  <div class="our-team">
                    <div class="pic">
                      <img src={require("../assets/images/team_01.png")} alt="Team" className='img-fluid' />
                      <ul class="social">
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-youtube"></a></li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <h3 class="title">Rinks Cooper</h3>
                      <span class="post">Chief Executive Officer</span>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3">
                <ScrollAnimation animateIn='slideInUp'>
                  <div class="our-team">
                    <div class="pic">
                      <img src={require("../assets/images/team_02.png")} alt="Team" className='img-fluid' />
                      <ul class="social">
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-youtube"></a></li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <h3 class="title">Darlene Robertson</h3>
                      <span class="post">Co-founder & COO</span>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3">
                <ScrollAnimation animateIn='slideInUp'>
                  <div class="our-team">
                    <div class="pic">
                      <img src={require("../assets/images/team_03.png")} alt="Team" className='img-fluid' />
                      <ul class="social">
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-youtube"></a></li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <h3 class="title">Dianne Russell</h3>
                      <span class="post">Co-founder & CTO</span>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3">
                <ScrollAnimation animateIn='slideInUp'>
                  <div class="our-team">
                    <div class="pic">
                      <img src={require("../assets/images/team_04.png")} alt="Team" className='img-fluid' />
                      <ul class="social">
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-youtube"></a></li>
                      </ul>
                    </div>
                    <div class="team-content">
                      <h3 class="title">Albert Flores</h3>
                      <span class="post">Co-founder & CIO</span>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section> */}

        {/* faq Section */}
        {/* <section className='grey_bg' name="faq">
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                <ScrollAnimation animateIn='slideInUp'><h3 className='main_title_small'>Ask Anything</h3></ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp'><h2 className='main_title'>Frequently Asked Quesions</h2></ScrollAnimation>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg-6'>
                <div className="accordion" id="faq_accodian">
                  <ScrollAnimation animateIn='flipInX'>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What is KaneDama?</button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faq_accodian">
                        <div className="accordion-body">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn='flipInX'>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Where can I subscribe to your newsletter?</button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq_accodian">
                        <div className="accordion-body">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn='flipInX'>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How can I participate in the Token sale? </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq_accodian">
                        <div className="accordion-body">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn='flipInX'>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How do I benefit from the Token? </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faq_accodian">
                        <div className="accordion-body">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
              <div className='col-md-12 col-lg-6 text-center'>
                <ScrollAnimation animateIn='slideInUp'><img src={require("../assets/images/img_03.png")} className='img-fluid' alt='FAQ' /></ScrollAnimation>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    </div>
  );
}